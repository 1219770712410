import { UISchemaElement } from '@jsonforms/core';
import { Box, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIosRounded, MenuRounded } from '@material-ui/icons';
import cx from 'classnames';
import { AgentLogo } from 'components/AgentLogo';
import { BottomActionsNavigation } from 'components/BottomActionsNavigation';
import { HelperBox } from 'components/HelperBox';
import { JsonForms } from 'components/JsonForms';
import { LinkButton } from 'components/LinkButton';
import { LoadingContainer } from 'components/LoadingContainer';
import { NotFoundContainer } from 'components/NotFoundContainer';
import { OverlayCard } from 'components/OverlayCard';
import { ProgressBarWithTitle } from 'components/ProgressBarWithTitle';
import { ApplicationStatus, UiSectionSchema } from 'dtos/application';
import { isArray } from 'lodash';
import debounce from 'lodash/debounce';
import intersection from 'lodash/intersection';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { useApplication, useClientEditApplication } from 'queries/useApplications';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createSecurityRoute, insertIdToRoute, ROUTES } from 'telivy-constants';
import { isScanApplication, isSecurityApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { isObjectEmpty } from 'telivy-utils';
// import { DnbModal } from 'views/client/DnbModal';
import { SubmitSection } from 'views/client/SubmitSection';

import { ApplicationSidebar } from './ApplicationSidebar';
import { ReactComponent as HeaderImage } from './images/client-application-header.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    position: 'relative',
    justifyContent: 'center',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },

  // Navbar
  nav: {},
  navDesktop: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(6),
    paddingTop: theme.spacing(4),
  },
  navMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  navMobileTitle: {
    ...TYPOGRAPHY.SMALL_BOLD,
  },
  navMobileButton: {
    outline: 'none',
  },
  hiddenButton: {
    visibility: 'hidden',
  },
  navMobileIcon: {
    color: COLORS.GREY_3,
    fontSize: theme.spacing(2.375),
    cursor: 'pointer',
  },
  closeMobileIcon: {
    fontSize: theme.spacing(3),
    marginTop: theme.spacing(-0.375),
  },
  logo: {
    marginRight: 'auto',
  },
  headerImage: {
    marginRight: 'auto',
    marginLeft: theme.spacing(-1.25),
  },

  wrapper: {
    display: 'grid',
    gridTemplateColumns: '270px 1fr 220px',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  content: {
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),

    [theme.breakpoints.up('md')]: {
      maxHeight: 'calc(100vh - 160px)',
      overflowY: 'auto',
    },

    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },

    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(3)}px ${theme.spacing(12)}px`,
    },
  },
  helpers: {},
  button: {
    '& + $button': {
      marginLeft: theme.spacing(2),
    },

    [theme.breakpoints.up('md')]: {
      '&:last-of-type': {
        marginLeft: 'auto',
      },
    },

    '&.Mui-disabled': {
      background: COLORS.BLUE_3,
    },
  },
  backButton: {
    border: 'none',
    color: COLORS.GREY_2,
  },
  actionsContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5),
    },
  },

  // Submitted container
  submittedContainer: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: COLORS.GREY_1,
    paddingTop: theme.spacing(16),

    '& h2': {
      margin: 0,
    },

    '& p': {
      ...TYPOGRAPHY.REGULAR_REGULAR,
      color: COLORS.GREY_2,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
      margin: 0,
    },
  },
  submittedLabel: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    width: 380,
    backgroundColor: COLORS.GREEN_1,
    borderRadius: theme.spacing(3),
    padding: theme.spacing(2),
    color: COLORS.WHITE,
  },
}));

// interface ParamsType {
//   id: string;
// }

const SUBMITTED_STATE_PATH = 'submit';
const SUBMITTED_STATE_IDX = 9999;

export const ClientView: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [showAllErrors, setShowAllErrors] = useState<boolean>(false);
  const [applicationData, setApplicationData] = useState<any>({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errorPaths, setErrorPaths] = useState<string[]>([]);
  // const [dnbOpen, setDnbOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [seenSectionsIds, setSeenSectionsIds] = useState<number[]>([]);

  const { data, isLoading, isError } = useApplication(id || '', {
    onSuccess: (data) => {
      setApplicationData(data.applicationResponse);

      if (
        ![ApplicationStatus.NOT_STARTED, ApplicationStatus.IN_PROGRESS, ApplicationStatus.IN_REVIEW].includes(
          data.applicationStatus,
        ) &&
        !isScanApplication(data)
      ) {
        if (isSecurityApplication(data)) {
          // if application is submitted redirect to the security view
          navigate(createSecurityRoute(ROUTES.application.security.ROOT, data.id, 'latest'));
        } else {
          // if application is submitted redirect to the quoting view
          navigate(insertIdToRoute(ROUTES.application.QUOTING, data.id));
        }
      }

      // if (data.dnbData && !data.dnbData.dnbDone) {
      //   setDnbOpen(true);
      // }
    },
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    const seenSectionsIdxFromStorage = data?.id ? localStorage.getItem(`assessment-${data.id}`) : null;
    const parsedSeenSections = seenSectionsIdxFromStorage ? JSON.parse(seenSectionsIdxFromStorage) : [];

    if (isArray(parsedSeenSections) && parsedSeenSections?.length > 0) {
      setSeenSectionsIds(parsedSeenSections);
    }
  }, [data?.id]);

  const { mutate: editApplication } = useClientEditApplication(id || '');

  const currentPageIdx = useMemo(() => {
    const pageIdentifier = location.pathname.split('/')[4];

    if (data) {
      const activePage = data.applicationVersion.uiSchema.sections.findIndex((el) => el.pageSlug === pageIdentifier);

      if (activePage >= 0) {
        return activePage;
      }
    }

    if (pageIdentifier === SUBMITTED_STATE_PATH) {
      return SUBMITTED_STATE_IDX;
    }

    return null;
  }, [location, data]);

  const currentPageData =
    currentPageIdx != null && data ? data.applicationVersion.uiSchema.sections[currentPageIdx] : null;
  const numberOfSections = data && data.applicationVersion.uiSchema.sections.length;

  const isFirstPage = useMemo(() => currentPageIdx === 0, [currentPageIdx]);
  const isLastPage = useMemo(
    () => (numberOfSections ? currentPageIdx === numberOfSections - 1 : false),
    [numberOfSections, currentPageIdx],
  );

  const isApplicationLocked =
    !isScanApplication(data) &&
    data?.applicationStatus !== ApplicationStatus.NOT_STARTED &&
    data?.applicationStatus !== ApplicationStatus.IN_PROGRESS;

  const redirectToTheForm = useCallback(() => {
    setShowAllErrors(true);
    data &&
      navigate(
        `${insertIdToRoute(ROUTES.application.FORM, id || '')}/${
          data.applicationVersion.uiSchema.sections[0].pageSlug
        }`,
      );
  }, [data, navigate, id]);

  const redirectToFirstSectionWithErrors = useCallback(() => {
    // if there are any errors on the previous pages, then redirect to the first page with errors
    if (!isApplicationLocked) {
      if (data) {
        setShowAllErrors(true);
        setShowErrors(true);
        const allPages = data.applicationVersion.uiSchema.sections;
        // all pages before the current one reduced into array of objects
        const allPagesReduced = map(allPages, (element) => ({
          // paths is an array with paths to field on current page
          paths: element.uiSchema.propertiesPaths,
          pageSlug: element.pageSlug,
        }));
        // all errors in the whole form
        const parsedPaths = errorPaths.map((p) => p.replace(/\.\d+\./, '.')); // handle arrays
        const firstSectionWithErrors = allPagesReduced.find((page) => {
          return intersection(page.paths, parsedPaths).length !== 0;
        });

        if (firstSectionWithErrors)
          navigate(`${insertIdToRoute(ROUTES.application.FORM, id || '')}/${firstSectionWithErrors.pageSlug}`);
      }
    }
  }, [navigate, id, data, errorPaths, isApplicationLocked]);

  const saveDataDebounced = useMemo(
    () =>
      debounce((editData) => {
        editApplication({
          applicationResponse: editData,
        });
        localStorage.setItem(`assessment-${data?.id}`, JSON.stringify(seenSectionsIds));
      }, 500),
    [data, editApplication, seenSectionsIds],
  );

  const handleUpdateData = useCallback(
    (updatedData: any) => {
      if (
        data &&
        !isObjectEmpty(updatedData) &&
        updatedData !== data.applicationResponse &&
        !isApplicationLocked &&
        updatedData?.organization_name &&
        data?.applicationResponse?.organization_name
      ) {
        saveDataDebounced(updatedData);
      }

      setApplicationData(updatedData);
    },
    [setApplicationData, saveDataDebounced, data, isApplicationLocked],
  );

  const goToPage = useCallback(
    (metadata: UiSectionSchema) => {
      const viewRoute = `${insertIdToRoute(ROUTES.application.FORM, id || '')}/${metadata.pageSlug}`;
      navigate(viewRoute);
      currentPageIdx != null && setSeenSectionsIds((prev) => uniq([...prev, currentPageIdx]));
    },
    [id, navigate, currentPageIdx],
  );

  const goToNextPage = useCallback(() => {
    if (data && isNumber(currentPageIdx)) {
      const nextPageIdx = currentPageIdx + 1;
      const nextPage = data.applicationVersion.uiSchema.sections[nextPageIdx];

      if (nextPage) {
        const viewRoute = `${insertIdToRoute(ROUTES.application.FORM, id || '')}/${nextPage.pageSlug}`;
        navigate(viewRoute);
        setSeenSectionsIds((prev) => uniq([...prev, currentPageIdx]));
      } else {
        navigate(`${insertIdToRoute(ROUTES.application.FORM, id || '')}/${SUBMITTED_STATE_PATH}`);
      }
    }
  }, [navigate, id, currentPageIdx, data]);

  const goToPreviousPage = useCallback(() => {
    if (data && isNumber(currentPageIdx)) {
      const prevPageIdx = currentPageIdx - 1;
      const prevPage = data.applicationVersion.uiSchema.sections[prevPageIdx];
      const lastPageIdx = data.applicationVersion.uiSchema.sections.length - 1;
      const lastPage = data.applicationVersion.uiSchema.sections[lastPageIdx];
      if (prevPage) {
        const viewRoute = `${insertIdToRoute(ROUTES.application.FORM, id || '')}/${prevPage.pageSlug}`;
        navigate(viewRoute);
        setSeenSectionsIds((prev) => uniq([...prev, currentPageIdx]));
      } else if (location.pathname.includes(SUBMITTED_STATE_PATH)) {
        const viewRoute = `${insertIdToRoute(ROUTES.application.FORM, id || '')}/${lastPage.pageSlug}`;
        navigate(viewRoute);
        setSeenSectionsIds((prev) => uniq([...prev, currentPageIdx]));
      }
    }
  }, [location, navigate, id, currentPageIdx, data]);

  const hasErrors = useMemo(() => {
    if (currentPageData && isNumber(currentPageIdx)) {
      const { propertiesPaths } = currentPageData.uiSchema;
      const parsedErrorsPaths = errorPaths.map((p) => p.replace(/\.\d+\./, '.')); // handle arrays
      const errors = intersection(propertiesPaths, parsedErrorsPaths);
      return errors.length !== 0;
    }
  }, [currentPageData, currentPageIdx, errorPaths]);

  const handleNext = useCallback(() => {
    if (hasErrors) {
      setShowErrors(true);
    } else {
      if (!isFormValid && isLastPage) {
        redirectToFirstSectionWithErrors();
      } else {
        !showAllErrors && setShowErrors(false);
        goToNextPage();
      }
    }
  }, [hasErrors, isFormValid, isLastPage, showAllErrors, redirectToFirstSectionWithErrors, goToNextPage]);

  if (isLoading) {
    return <LoadingContainer fullScreen />;
  }

  if (isError || !data) {
    return <NotFoundContainer fullScreen />;
  }

  if (data && currentPageIdx === null) {
    const defaultRoute = `${insertIdToRoute(ROUTES.application.FORM, id || '')}/${
      data.applicationVersion.uiSchema.sections[0].pageSlug
    }`;

    navigate(defaultRoute);
  }

  return (
    <div className={classes.root}>
      <Helmet titleTemplate={`%s – ${data?.agent?.agency?.name || ''}`}>
        <title>{data?.applicationResponse?.organization_name || 'Client'} Assessment</title>
        <link id='favicon-32' rel='icon' type='image/x-icon' sizes='32x32' href={data?.agent?.agency?.logoUrl || ''} />
        <link id='favicon-16' rel='icon' type='image/x-icon' sizes='16x16' href={data?.agent?.agency?.logoUrl || ''} />
      </Helmet>
      <Hidden mdUp>
        <nav className={classes.navMobile}>
          <LinkButton
            className={cx(classes.navMobileButton, isFirstPage && classes.hiddenButton)}
            onClick={goToPreviousPage}
          >
            <ArrowBackIosRounded className={classes.navMobileIcon} />
          </LinkButton>
          {currentPageData?.label && currentPageData?.progress ? (
            <ProgressBarWithTitle label={currentPageData.label} progress={currentPageData.progress} />
          ) : (
            <span className={classes.navMobileTitle}>
              {isSecurityApplication(data) ? 'Submit Assessment' : 'Submit Application'}
            </span>
          )}

          <LinkButton className={classes.navMobileButton} onClick={() => setMenuOpen(true)}>
            <MenuRounded className={cx(classes.navMobileIcon, classes.closeMobileIcon)} />
          </LinkButton>
        </nav>
      </Hidden>
      <Hidden mdUp>
        <OverlayCard
          title={isSecurityApplication(data) ? 'Assessment' : 'Application'}
          open={menuOpen}
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
        >
          <ApplicationSidebar
            application={data}
            isApplicationLocked={isApplicationLocked}
            currentPageIdx={currentPageIdx}
            goToPage={(section: UiSectionSchema) => {
              goToPage(section);
              currentPageIdx != null && setSeenSectionsIds((prev) => uniq([...prev, currentPageIdx]));
            }}
            errorPaths={errorPaths}
            showAllErrors={isSecurityApplication(data) || showAllErrors}
            closeMenu={() => setMenuOpen(false)}
            seenSectionsIds={seenSectionsIds}
          />
        </OverlayCard>
      </Hidden>
      <Hidden smDown>
        <nav className={classes.navDesktop}>
          <AgentLogo applicationId={id || ''} logoSize={60} className={classes.logo} />
          <HeaderImage className={classes.headerImage} />
        </nav>
      </Hidden>

      {/*data?.dnbData && !data.dnbData.dnbDone && (
        <DnbModal application={data} open={dnbOpen} onClose={() => setDnbOpen(false)} />
      )*/}
      <div className={classes.wrapper}>
        <Hidden smDown>
          <ApplicationSidebar
            application={data}
            isApplicationLocked={isApplicationLocked}
            currentPageIdx={currentPageIdx}
            goToPage={(section: UiSectionSchema) => {
              goToPage(section);
              currentPageIdx != null && setSeenSectionsIds((prev) => uniq([...prev, currentPageIdx]));
            }}
            errorPaths={errorPaths}
            showAllErrors={isSecurityApplication(data) || showAllErrors}
            seenSectionsIds={seenSectionsIds}
          />
        </Hidden>

        <main className={classes.content}>
          {currentPageIdx === SUBMITTED_STATE_IDX && data ? (
            <SubmitSection isValid={isFormValid} application={data} redirectToTheForm={redirectToTheForm} />
          ) : (
            <>
              <Box m={1}>
                {currentPageData && data && (
                  <JsonForms
                    readonly={isApplicationLocked}
                    schema={data.applicationVersion.dataSchema}
                    uischema={currentPageData.uiSchema as UISchemaElement} // JSON Forms typings sucks...
                    data={applicationData}
                    onChange={({ data, errors }) => {
                      handleUpdateData(data);
                      setIsFormValid(errors ? errors.length === 0 : false);
                      setErrorPaths(uniq((errors || []).map((e) => e.dataPath)));
                    }}
                    validationMode={showErrors ? 'ValidateAndShow' : 'ValidateAndHide'}
                    applicationSource='client'
                  />
                )}
              </Box>
              <BottomActionsNavigation roundedButton className={classes.actionsContainer}>
                <Hidden smDown>
                  {!isFirstPage && (
                    <Button size='large' onClick={goToPreviousPage} className={cx(classes.button, classes.backButton)}>
                      Back
                    </Button>
                  )}
                </Hidden>

                <Button
                  size='large'
                  variant='contained'
                  color='primary'
                  onClick={handleNext}
                  className={classes.button}
                  disabled={showErrors ? hasErrors : false}
                  data-cy='form-next-button'
                >
                  Next
                </Button>
              </BottomActionsNavigation>
            </>
          )}
        </main>

        {currentPageData?.helperBox?.description && (
          <Hidden smDown>
            <div className={classes.helpers}>
              <HelperBox title={currentPageData.helperBox.title} description={currentPageData.helperBox.description} />
            </div>
          </Hidden>
        )}
      </div>
    </div>
  );
};
