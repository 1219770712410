import { makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import cx from 'classnames';
import { LoadingContainer } from 'components/LoadingContainer';
import { Searchbar } from 'components/SearchBar';
import { SingleFilter } from 'components/SingleFilter';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import { ApplicationWithAgentDTO, MonitoringSnapshotDTO, SecurityScanDTO } from 'dtos/application';
import { ApplicationsRiskReport } from 'dtos/deep-scan';
import { SecurityFindingBySlugDTO, SecurityScanType } from 'dtos/security-finding';
import { useMonitoringApplicationSecurityChart } from 'hooks/charts/monitoring/useApplicationSecurityChart';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { useExternalScanPreviousFindings } from 'hooks/external-scan/useExternalScanPreviousFindings';
import { useChartImage } from 'hooks/useChartImage';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import { useDeepScan, useDeepScanApplicationRiskUser, useDeepScanParsedReports } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { createSecurityRoute } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { LIMITS } from 'views/agent/views/application-details/views/monitoring/RiskAssessmentHistory';

import { SourceRoute } from '../..';
import { renderScanResults } from '../../components/ExternalScanItem';
import { ScoreBox, ScoreRanking } from '../../components/ScoreBox';

export enum BarsBy {
  VISITS = 'visits',
  USERS = 'users',
}

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },

  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },

  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },

  red: {
    color: COLORS.RED_1,
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flex: 1,
  },
  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
  },

  headerRow: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  chartContainer: {
    flex: 1,
    display: 'flex',
    width: '100%',
    // height: '100%',
  },
  chart: {
    height: 380,
  },

  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  lineChart: {
    height: 400,
  },
  redItalic: {
    color: COLORS.RED_1,
    fontStyle: 'italic',
  },
  filterBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  sourceRoute: SourceRoute;
  securityScanId: string;
  application: ApplicationWithAgentDTO;
  monitoringSnapshots?: MonitoringSnapshotDTO[];
  selectedLimit: string;
  setSelectedLimit: (a: string) => void;
  isAdmin?: boolean;
}

const PER_PAGE = 6;
export const DeepScanApplicationSecurity = ({
  applicationId,
  sourceRoute,
  securityScan,
  securityScanId,
  application,
  monitoringSnapshots,
  selectedLimit,
  setSelectedLimit,
}: Props) => {
  const [bars, setBars] = useState(BarsBy.VISITS);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [appsUserPage, setAppsUserPage] = useState(0);
  const { findingsByType, isErrorScanNetwork, isLoadingScanNetwork } = useExternalScanFindings({ securityScan });
  const { previousFindingsByType } = useExternalScanPreviousFindings({ securityScan });
  const [sorter, setSorter] = useState<Sorter<keyof ApplicationsRiskReport> | undefined>({
    key: 'visits',
    order: SortOrder.DESC,
  });
  const [appsUserSorter, setAppsUserSorter] = useState<Sorter<keyof ApplicationsRiskReport> | undefined>({
    key: 'visits',
    order: SortOrder.DESC,
  });
  const getFindingUrl = (finding: SecurityFindingBySlugDTO) =>
    createSecurityRoute(sourceRoute.security.FINDING, applicationId, securityScanId, finding.slug);

  const [selectedAppsDevices, setSelectedAppsDevices] = useState<string[]>([]);
  const [selectedAppsUsers, setSelectedAppsUsers] = useState<string[]>([]);
  const [selectedAppsCategories, setSelectedAppsCategories] = useState<string[]>([]);
  const [selectedAppsApplications, setSelectedAppsApplications] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);

  const deepScanId = application.deepScanId || '';
  const { data: report, isLoading: isLoadingReport } = useDeepScanParsedReports(deepScanId, {
    enabled: Boolean(deepScanId),
  });
  const { data: appsUserData, isLoading: isLoadingApplicationRiskUser } = useDeepScanApplicationRiskUser(deepScanId, {
    enabled: Boolean(deepScanId),
  });
  const { data: deepScan } = useDeepScan(deepScanId);
  const { data: charts } = useApplicationSecurityChartsUrls(application.id);
  const chart = useChartImage(
    {
      className: classes.chart,
      title: 'Applications by',
      actions: (
        <Select
          value={bars}
          onChange={(el) => {
            setBars(el.target.value as BarsBy);
          }}
        >
          <MenuItem key={BarsBy.VISITS} value={BarsBy.VISITS}>
            Visits
          </MenuItem>
          <MenuItem key={BarsBy.USERS} value={BarsBy.USERS}>
            Users
          </MenuItem>
        </Select>
      ),
    },
    bars === BarsBy.VISITS ? charts?.applicationsByVisits : charts?.applicationsByUsers,
    deepScan,
    application,
  );

  const columns = useMemo((): Column<ApplicationsRiskReport, keyof ApplicationsRiskReport>[] => {
    return [
      {
        title: 'Application',
        sortKey: 'application',
        render: (row) => row.application,
      },
      {
        title: 'Category',
        sortKey: 'category',
        render: (row) => row.category,
      },
      // {
      //   title: 'Days Active',
      //   sortKey: 'daysActive',
      //   render: (row) => row.daysActive,
      // },
      {
        title: 'Total Visits',
        sortKey: 'visits',
        render: (row) => row.visits,
      },
      {
        title: 'Risk',
        sortKey: 'risk',
        render: (row: ApplicationsRiskReport) => <ScoreBox ranking={row.risk as ScoreRanking} />,
      },
      {
        title: 'Users',
        sortKey: 'users',
        render: (row) => row.users,
      },
    ];
  }, []);

  const rawData = useMemo(() => {
    return report?.applicationsRiskReport || [];
  }, [report]);

  const sortedData = useMemo(() => {
    if (sorter) {
      const data = sortBy(rawData, sorter.key);

      if (sorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(rawData, (d) => d.visits).reverse();
  }, [rawData, sorter]);

  const appsUserColumns = useMemo((): Column<ApplicationsRiskReport, keyof ApplicationsRiskReport>[] => {
    return [
      {
        title: 'Device',
        sortKey: 'deviceName',
        render: (row) => row.deviceName,
      },
      {
        title: 'User',
        sortKey: 'userName',
        render: (row) => row.userName,
      },
      {
        title: 'Application',
        sortKey: 'application',
        render: (row) => row.application,
      },
      {
        title: 'Category',
        sortKey: 'category',
        render: (row) => row.category,
      },
      {
        title: 'Total Visits',
        sortKey: 'visits',
        render: (row) => row.visits,
      },
      {
        title: 'Risk',
        sortKey: 'risk',
        render: (row: ApplicationsRiskReport) => <ScoreBox ranking={row.risk as ScoreRanking} />,
      },
    ];
  }, []);

  const sortedAppsUserData = useMemo(() => {
    const filtered = appsUserData
      ?.filter((b) => {
        if (!debouncedSearchValue) {
          return true;
        }

        return (
          b.userName?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          b.deviceName?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          b.application.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          b.category.toLowerCase().includes(debouncedSearchValue.toLowerCase())
        );
      })
      .filter((b) => {
        if (selectedAppsDevices.length) {
          return b.deviceName && selectedAppsDevices.includes(b.deviceName);
        } else {
          return true;
        }
      })
      .filter((b) => {
        if (selectedAppsUsers.length) {
          return b.userName && selectedAppsUsers.includes(b.userName);
        } else {
          return true;
        }
      })
      .filter((b) => {
        if (selectedAppsCategories.length) {
          return b.category && selectedAppsCategories.includes(b.category);
        } else {
          return true;
        }
      })
      .filter((b) => {
        if (selectedAppsApplications.length) {
          return b.application && selectedAppsApplications.includes(b.application);
        } else {
          return true;
        }
      });

    if (appsUserSorter) {
      const data = sortBy(filtered, appsUserSorter.key);

      if (appsUserSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(filtered, (d) => d.visits).reverse();
  }, [
    appsUserData,
    appsUserSorter,
    debouncedSearchValue,
    selectedAppsUsers,
    selectedAppsCategories,
    selectedAppsDevices,
    selectedAppsApplications,
  ]);

  const appsUsers: string[] = useMemo(
    () => uniq(appsUserData?.filter((u) => u.userName).map((apr) => apr.userName as string) ?? []).sort(),
    [appsUserData],
  );

  const appsCategories: string[] = useMemo(
    () => uniq(appsUserData?.filter((u) => u.category).map((apr) => apr.category as string) ?? []).sort(),
    [appsUserData],
  );

  const appsDevices: string[] = useMemo(
    () => uniq(appsUserData?.filter((u) => u.deviceName).map((apr) => apr.deviceName as string) ?? []).sort(),
    [appsUserData],
  );

  const appsApplications: string[] = useMemo(
    () => uniq(appsUserData?.filter((u) => u.application).map((apr) => apr.application) ?? []).sort(),
    [appsUserData],
  );

  const { chart: applicationSecurityChart } = useMonitoringApplicationSecurityChart(
    application,
    application.monitoringEnabled,
    monitoringSnapshots,
    classes.lineChart,
  );

  const isLoadingAny = !securityScan || isLoadingReport || isLoadingApplicationRiskUser;

  if (isLoadingAny) {
    return <LoadingContainer />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Application Security</div>

      <div className={classes.section}>
        <div className={classes.summary} id='summary'>
          <div className={classes.item}>
            <div className={classes.number}>{rawData.length.toLocaleString()}</div>
            <div className={classes.description}>Total Applications Found</div>
          </div>
          <div className={classes.item}>
            <div className={cx(classes.number, classes.red)}>
              {rawData.filter((a) => a.risk == 'High').length.toLocaleString()}
            </div>
            <div className={classes.description}>High Risk Applications</div>
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Risky/Personal Applications
          <span className={classes.findings}> ({rawData.length} findings)</span>
        </div>

        <div className={classes.headerRow}>
          <div className={classes.chartContainer}>{chart}</div>
          <div className={classes.boxBorder}>
            <Table<ApplicationsRiskReport, keyof ApplicationsRiskReport>
              columns={columns}
              sorter={sorter}
              rowContentCentered
              onChange={(newPage, sorting) => {
                if (newPage?.page !== undefined) {
                  setPage(newPage.page);
                }

                setSorter(sorting);
              }}
              className={classes.table}
              rowKey={(row) => row.application}
              data={sortedData.slice(page * PER_PAGE, (page + 1) * PER_PAGE)}
              loading={isLoadingReport}
              pagination={isLoadingReport ? undefined : { page, perPage: PER_PAGE, total: rawData?.length || 0 }}
            />
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.filterBar}>
          <div className={classes.title}>
            Risk Over Time
            <br />
            <span className={classes.redItalic}> Number of Risky Applications Visited</span>
          </div>
          <div>
            <SingleFilter
              type='radio'
              buttonText={selectedLimit}
              defaultSelectedOptions={[selectedLimit]}
              popperText='Select Period'
              showDropdownIcon
              options={Object.keys(LIMITS)}
              setSelectedOptions={(a) => {
                setSelectedLimit(a[0]);
              }}
              startIcon={<></>}
              className={classes.marginLeft}
            />
          </div>
        </div>
        <div className={classes.chartContainer}>{applicationSecurityChart}</div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Applications By User
          <span className={classes.findings}> ({sortedAppsUserData.length} findings)</span>
        </div>

        <div>
          <div className={classes.boxBorderPadding}>
            <div className={classes.filters}>
              <Searchbar placeholder='Search...' value={searchValue} setValue={setSearchValue} />
              {appsDevices.length > 1 && (
                <SingleFilter
                  buttonText={`Filter Devices ${selectedAppsDevices.length > 0 ? `[${selectedAppsDevices}]` : ''}`}
                  defaultSelectedOptions={selectedAppsDevices}
                  popperText='Devices'
                  showDropdownIcon
                  options={appsDevices}
                  setSelectedOptions={(d) => {
                    setSelectedAppsDevices(d);
                    setAppsUserPage(0);
                  }}
                />
              )}
              {appsUsers.length > 1 && (
                <SingleFilter
                  buttonText={`Filter Users ${selectedAppsUsers.length > 0 ? `[${selectedAppsUsers}]` : ''}`}
                  defaultSelectedOptions={selectedAppsUsers}
                  popperText='Users'
                  showDropdownIcon
                  options={appsUsers}
                  setSelectedOptions={(u) => {
                    setSelectedAppsUsers(u);
                    setAppsUserPage(0);
                  }}
                />
              )}
              {appsCategories.length > 0 && (
                <SingleFilter
                  buttonText={`Filter Categories ${
                    selectedAppsCategories.length > 0 ? `[${selectedAppsCategories}]` : ''
                  }`}
                  defaultSelectedOptions={selectedAppsCategories}
                  popperText='Categories'
                  showDropdownIcon
                  options={appsCategories}
                  setSelectedOptions={(u) => {
                    setSelectedAppsCategories(u);
                    setAppsUserPage(0);
                  }}
                />
              )}
              <SingleFilter
                buttonText={`Filter Applications ${
                  selectedAppsApplications.length > 0 ? `[${selectedAppsApplications}]` : ''
                }`}
                defaultSelectedOptions={selectedAppsApplications}
                popperText='Applications'
                showDropdownIcon
                options={appsApplications}
                setSelectedOptions={(a) => {
                  setSelectedAppsApplications(a);
                  setAppsUserPage(0);
                }}
              />
            </div>

            <Table<ApplicationsRiskReport, keyof ApplicationsRiskReport>
              columns={appsUserColumns}
              sorter={appsUserSorter}
              rowContentCentered
              onChange={(newPage, sorting) => {
                if (newPage?.page !== undefined) {
                  setAppsUserPage(newPage.page);
                }

                setAppsUserSorter(sorting);
              }}
              className={classes.table}
              rowKey={(row) => row.application}
              data={sortedAppsUserData.slice(appsUserPage * PER_PAGE, (appsUserPage + 1) * PER_PAGE)}
              loading={isLoadingApplicationRiskUser}
              pagination={
                isLoadingApplicationRiskUser
                  ? undefined
                  : { page: appsUserPage, perPage: PER_PAGE, total: sortedAppsUserData?.length || 0 }
              }
            />
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Website Vulnerabilities
          <span className={classes.findings}>
            {' '}
            ({findingsByType[SecurityScanType.APPLICATION_SECURITY].filter((f) => f.count > 0).length} findings)
            {isLoadingScanNetwork ? '(External scan ongoing)' : ''}
          </span>
        </div>
        <div className={classes.boxBorder}>
          {(!isErrorScanNetwork || securityScan.previousScan?.networkScannedAt) && (
            <div className={classes.scanResultsWrapper}>
              {renderScanResults(
                findingsByType[SecurityScanType.APPLICATION_SECURITY] || [],
                getFindingUrl,
                true,
                false,
                previousFindingsByType[SecurityScanType.APPLICATION_SECURITY] || undefined,
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
