import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import snakeCase from 'lodash/snakeCase';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StyleProps {
  responsive?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: ({ responsive }) => ({
    backgroundColor: COLORS.BLUE_3,
    overflow: 'hidden',
    display: 'inline-flex',
    borderRadius: theme.spacing(1),
    position: 'relative',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      ...(responsive && {
        alignItems: 'initial',
        display: 'flex',
        flexDirection: 'column',
      }),
    },
  }),
  divider: ({ responsive }) => ({
    display: 'block',
    width: 1,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    height: theme.spacing(2),
    backgroundColor: COLORS.BLUE_2,

    [theme.breakpoints.down('sm')]: {
      ...(responsive && {
        display: 'none',
      }),
    },
  }),
  option: {
    margin: 2,
    backgroundColor: 'transparent',
    borderRadius: theme.spacing(1),
    border: 'none',
    ...TYPOGRAPHY.SMALL_MEDIUM,
    color: COLORS.GREY_2,
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.WHITE,
      boxShadow: '0px 2px 4px rgba(35, 40, 48, 0.1), 0px 0px 1px rgba(35, 40, 48, 0.2)',
    },
  },
  selected: {
    color: COLORS.BLUE_1,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px rgba(35, 40, 48, 0.1), 0px 0px 1px rgba(35, 40, 48, 0.2)',
  },
  count: {
    ...TYPOGRAPHY.MONO_MEDIUM_EXTRA_SMALL,
    marginLeft: theme.spacing(0.5),
    position: 'relative',
    bottom: 1,
  },
}));

export interface Option<T> {
  label: string;
  value: T;
  count?: number;
}

interface Props<T> extends StyleProps {
  className?: string;
  options: Option<T>[];
  selectedValue?: T;
  id?: string;
  onOptionSelect: (o: Option<T>) => void;
  selectedFn?: (optionValue: T, selectedValue?: T) => boolean;
  noDivider?: boolean;
}

export function RadioSelect<T>({
  className,
  options,
  selectedValue,
  onOptionSelect,
  id,
  responsive,
  selectedFn,
  noDivider,
}: Props<T>) {
  const classes = useStyles({ responsive });

  return (
    <div className={cx(classes.root, className)} id={id}>
      {options.map((option, idx) => {
        const buttonClasses = cx(classes.option, {
          [classes.selected]: selectedFn ? selectedFn(option.value, selectedValue) : selectedValue === option.value,
        });

        return [
          idx !== 0 && !noDivider ? <span className={classes.divider} key={`divider-${idx}`} /> : null,
          <button
            type='button'
            className={buttonClasses}
            key={idx}
            onClick={() => onOptionSelect(option)}
            id={`${id}-${snakeCase(option.label)}`}
          >
            {option.label}
            {option.count !== undefined ? <span className={classes.count}>({option.count})</span> : null}
          </button>,
        ];
      })}
    </div>
  );
}
