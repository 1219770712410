import { makeStyles } from '@material-ui/core';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import dayjs from 'dayjs';
import {
  combineDeepScanActions,
  combineDeepScanIssues,
  computeUserApplicationsStatus,
  computeUserBrowserPasswordStatus,
  computeUserPiiStatus,
  ExtendedDeepScanTargetDTO,
} from 'dtos/deep-scan';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { TYPOGRAPHY } from 'telivy-theme';

import { DetailsColumn } from './DetailsColumn';
// import { DetailsModal } from './DetailsModal';
import { LogsModal } from './LogsModal';
import { RemoveButton } from './RemoveButton';
import { RescanButton } from './RescanButton';
import { TargetStatus } from './TargetStatus';
import { UninstallButton } from './UninstallButton';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
  macColumn: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  expandedRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    gap: theme.spacing(4),
    padding: theme.spacing(2),
    paddingRight: theme.spacing(12),
    borderRadius: theme.spacing(1),
    backgroundColor: 'rgba(65, 80, 93, 0.05)',
    border: '1px dashed rgba(65, 80, 93, 0.2)',
  },

  expandedRowNotes: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    display: 'flex',
    gap: theme.spacing(1),
  },

  expandedColTitle: {
    ...TYPOGRAPHY.SMALL_BOLD,
    textDecoration: 'underline',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
  },

  expandedColContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1),
    flexDirection: 'column',
  },

  expandedColDescription: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    marginRight: theme.spacing(3),
  },

  adminStatuses: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const generateKey = (row: ExtendedDeepScanTargetDTO) => `${row.macAddress}-${row.hostname}`;

interface Props {
  targets: ExtendedDeepScanTargetDTO[];
  deepScanId: string;
  isAdmin: boolean;
  enableCharts: boolean;
  isDemo?: boolean;
  locked?: boolean;
}

export const TargetsTable = ({ targets, isAdmin, enableCharts, isDemo, locked }: Props) => {
  const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
  const classes = useStyles();
  const [sorter, setSorter] = useState<Sorter<string> | undefined>(undefined);

  const columns = useMemo((): Column<ExtendedDeepScanTargetDTO, string>[] => {
    const columns: Column<ExtendedDeepScanTargetDTO, string>[] = [
      {
        title: 'Host',
        sortKey: 'hostname',
        render: (row) => <div className={classes.macColumn}>{row.hostname}</div>,
      },
      {
        title: 'OS Version',
        sortKey: 'osVersion',
        render: (row) => <div className={classes.macColumn}>{row.osVersion}</div>,
      },
      {
        width: '20%',
        title: 'Agent Status',
        sortKey: 'statuses.telivyAgent.status',
        render: (row) => (
          <TargetStatus status={row.statuses.telivyAgent.status}>{row.statuses.telivyAgent.statusMessage}</TargetStatus>
        ),
      },
      {
        width: '20%',
        title: 'Password Status',
        sortKey: 'statuses.browserPassword.status',
        render: (row) => (
          <TargetStatus status={row.statuses.browserPassword.status}>
            {row.statuses.browserPassword.statusMessage}
          </TargetStatus>
        ),
      },
    ];

    // if (isAdmin || enableCharts) {
    columns.push({
      width: '20%',
      title: 'Actions',
      render: (row) => {
        const deviceOnlineIn7Days =
          isDemo || (row.lastHeartBeatAt && moment().diff(moment(row.lastHeartBeatAt), 'days') <= 3);

        const showUninstall =
          deviceOnlineIn7Days &&
          // enableCharts &&
          row.telivyAgentDetails?.service_log &&
          !row.telivyAgentUninstalledAt &&
          !row.telivyAgentDetails?.uninstall_scheduled &&
          !row.telivyAgentDetails?.rescan_scheduled;

        const showRescan =
          deviceOnlineIn7Days &&
          enableCharts &&
          row.telivyAgentDetails?.service_log &&
          !row.telivyAgentUninstalledAt &&
          !row.telivyAgentDetails?.uninstall_scheduled &&
          !row.telivyAgentDetails?.uninstall_all_started &&
          !row.telivyAgentDetails?.rescan_scheduled;

        const showRemove = true;

        const showOffline =
          !deviceOnlineIn7Days &&
          !row.telivyAgentUninstalledAt &&
          (row.telivyAgentDetails?.stdout ||
            row.telivyAgentInstalledAt ||
            row.telivyAgentDetails?.uninstall_scheduled) &&
          !row.osVersion?.includes('macOS');

        return (
          <div>
            <div className={classes.macColumn}>
              {showRescan && (
                <div>
                  <RescanButton deepScanTarget={row} locked={locked} />
                </div>
              )}
              {showUninstall && (
                <div>
                  <UninstallButton deepScanTarget={row} />
                </div>
              )}
              {showRemove && (
                <div>
                  <RemoveButton deepScanTarget={row} />
                </div>
              )}
              {showOffline && <div>Device Not Connected</div>}
            </div>
          </div>
        );
      },
    });
    // }

    return columns;
  }, [classes.macColumn, enableCharts, isDemo, locked]);

  const sortedTargets = useMemo(() => {
    if (sorter && targets) {
      const data = sortBy(targets, sorter.key);

      if (sorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return targets.sort((a, b) => {
      return (
        (b.telivyAgentInstalledAt ? (b.browserPasswordExecutedAt ? 2 : 1) : b.browserPasswordExecutedAt ? 1 : 0) -
        (a.telivyAgentInstalledAt ? (a.browserPasswordExecutedAt ? 2 : 1) : a.browserPasswordExecutedAt ? 1 : 0)
      );
    });
  }, [targets, sorter]);

  const handleRowClick = (row: ExtendedDeepScanTargetDTO) => {
    setExpandedRows((prev) => {
      if (prev.includes(generateKey(row))) {
        return prev.filter((r) => r !== generateKey(row));
      }

      return [...prev, generateKey(row)];
    });
  };

  return (
    <Table<ExtendedDeepScanTargetDTO, string>
      columns={columns}
      rowContentCentered
      className={classes.table}
      rowKey={generateKey}
      data={sortedTargets}
      sorter={sorter}
      onChange={(pagination, sorter) => {
        setSorter(sorter);
      }}
      expandedRowKeys={expandedRows}
      onRowClick={handleRowClick}
      onExpandRowClick={handleRowClick}
      onCollapseExpandAllClick={(type) => {
        if (type === 'expand') {
          setExpandedRows(targets.map(generateKey));
        } else {
          setExpandedRows([]);
        }
      }}
      expandedRowRender={(row) => {
        const actions = combineDeepScanActions(row.statuses);
        const issues = combineDeepScanIssues(row.statuses);
        const remoteCommand = row.remoteCommands[(row.controllerLogs || []).length];

        // const nodewareLogs =
        //   row.telivyAgentDetails &&
        //   (row.telivyAgentDetails.nodeware_logs ||
        //     row.telivyAgentDetails.nodeware_error ||
        //     row.telivyAgentDetails.nodeware_installed);

        return (
          <div className={classes.expandedRow}>
            <div>
              {issues.length > 0 ? (
                <div className={classes.expandedRowNotes}>
                  <b>Issues:</b>
                  {issues.join(', ')}
                </div>
              ) : null}

              {actions.length > 0 ? (
                <div className={classes.expandedRowNotes}>
                  <b>Action items:</b>
                  {actions.join(', ')}
                </div>
              ) : null}

              {isAdmin && (
                <div className={classes.adminStatuses}>
                  <div className={classes.macColumn}>
                    <b>Controller status:</b>
                    {row.lastHeartBeatAt && `Online on ${dayjs(row.lastHeartBeatAt).format('M/D/YYYY h:mm A')}`}

                    {(row.controllerLogs || []).length > 0 && (
                      <div>
                        <LogsModal title='Telivy Controller Logs' logs={row.controllerLogs} />
                      </div>
                    )}

                    <div>
                      {(row.remoteCommands || []).length > 0 && remoteCommand && <div>Scheduled: {remoteCommand}</div>}
                    </div>
                  </div>

                  <DetailsColumn
                    title='Telivy Agents Status'
                    details={row.telivyAgentDetails}
                    date={row.telivyAgentInstalledAt}
                    showInstallData={true}
                    checkRunAsAdmin={true}
                    isAdmin={isAdmin}
                  >
                    <b>Agent status:</b>
                  </DetailsColumn>

                  <DetailsColumn
                    title='Browser Password Status'
                    details={row.browserPasswordExecutionDetails}
                    date={row.browserPasswordExecutedAt}
                    isAdmin={isAdmin}
                    checkInstalledBrowsers={true}
                  >
                    <b>Browser Password status:</b>
                  </DetailsColumn>
                </div>
              )}
            </div>

            <div>
              <div className={classes.expandedColTitle}>Agent Status:</div>
              <div className={classes.expandedColContent}>
                <div className={classes.expandedColDescription}>Vulnerability:</div>
                <TargetStatus status={row.statuses.vulnerabilityManagement.status}>
                  {row.statuses.vulnerabilityManagement.statusMessage}
                </TargetStatus>

                {/*nodewareLogs && (
                  <>
                    <div className={classes.expandedColDescription}>Logs:</div>
                    <div className={classes.expandedColDescription}>
                      <DetailsModal
                        title='Logs'
                        details={row.telivyAgentDetails}
                        showOnly={['nodeware_logs', 'nodeware_error', 'nodeware_installed']}
                      />
                    </div>
                  </>
                )*/}

                <div className={classes.expandedColDescription}>Application:</div>
                <TargetStatus status={row.statuses.application.status}>
                  {row.statuses.application.statusMessage}
                </TargetStatus>

                <div className={classes.expandedColDescription}>PII Agent:</div>
                <TargetStatus status={row.statuses.piiAgent.status}>{row.statuses.piiAgent.statusMessage}</TargetStatus>

                {row.telivyAgentInstalledAt ? (
                  <>
                    <div className={classes.expandedColDescription}>
                      {row.telivyAgentDetails?.service_log &&
                        row.telivyAgentDetails?.rescan_started &&
                        'Re Scanned At:'}
                      {row.telivyAgentDetails?.service_log &&
                        !row.telivyAgentDetails?.rescan_started &&
                        'Installed At:'}
                      {!row.telivyAgentDetails?.service_log && 'Scanned At:'}
                    </div>
                    <div className={classes.expandedColDescription}>
                      {dayjs(row.telivyAgentInstalledAt).format('M/D/YYYY h:mm A')}
                    </div>
                  </>
                ) : null}

                {row.telivyAgentUninstalledAt ? (
                  <>
                    <div className={classes.expandedColDescription}>Uninstalled At:</div>
                    <div className={classes.expandedColDescription}>
                      {dayjs(row.telivyAgentUninstalledAt).format('M/D/YYYY h:mm A')}
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {Object.keys(row.browserPasswordExecutionDetails || {}).length > 0 ? (
              <div>
                <div className={classes.expandedColTitle}>Users Status:</div>
                <div className={classes.expandedColContent}>
                  {Object.keys(row.browserPasswordExecutionDetails || {}).map((user) => {
                    const status = computeUserBrowserPasswordStatus(row, row.browserPasswordExecutionDetails?.[user]);

                    const appsStatus = computeUserApplicationsStatus(row.browserPasswordExecutionDetails?.[user]);
                    const appsResults = [];
                    if (appsStatus) {
                      appsResults.push(<div></div>);
                      appsResults.push(
                        <TargetStatus status={appsStatus.status} key={`${user}-apps-stat`}>
                          {appsStatus.statusMessage}
                        </TargetStatus>,
                      );
                    }

                    const piiStatus = computeUserPiiStatus(row.browserPasswordExecutionDetails?.[user]);
                    const piiResults = [];
                    if (piiStatus) {
                      appsResults.push(<div></div>);
                      piiResults.push(
                        <TargetStatus status={piiStatus.status} key={`${user}-pii-stat`}>
                          {piiStatus.statusMessage}
                        </TargetStatus>,
                      );
                    }

                    return [
                      <div key={`${user}-name`} className={classes.expandedColDescription}>
                        {user}:
                      </div>,
                      <TargetStatus status={status.status} key={`${user}-stat`}>
                        {status.statusMessage}
                      </TargetStatus>,
                      ...appsResults,
                      ...piiResults,
                    ];
                  })}
                </div>
              </div>
            ) : null}
          </div>
        );
      }}
    />
  );
};
