/* eslint-disable react/no-unescaped-entities */
import { Container, makeStyles } from '@material-ui/core';
import Navbar from 'components/Layout/Navbar';
import { LoadingContainer } from 'components/LoadingContainer';
import { useCurrentAgent } from 'queries/useAgent';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  // createSecurityRoute,
  GO_SQUARED_SNIPPET,
  ROUTES,
  WESTERN_INSURANCE_ANALYTICS_NOSCRIPT_IFRAME_URL,
  WESTERN_INSURANCE_ANALYTICS_SNIPPET,
} from 'telivy-constants';
import { isSecurityAgency, isWesternInsuranceAgent } from 'telivy-selectors';

import { AccountView } from './views/account';
import { AdminView } from './views/admin';
import { ApplicationDetailsView } from './views/application-details';
import { AgentCrmView } from './views/crm';
import { AgentDashboardView } from './views/dashboard';
import { AgentSummaryView } from './views/summary';

const useStyles = makeStyles((theme) => ({
  root: {},
  loadingContainer: {
    display: 'flex',
    padding: theme.spacing(4),
    justifyContent: 'center',
  },
  cointainer: {
    margin: 0,
    padding: 0,
  },
}));

export const AgentView: React.FC = () => {
  const classes = useStyles();
  const { data, isLoading } = useCurrentAgent();
  const ROOT_URL = ROUTES.agent.DASHBOARD;
  // isSecurityAgency(data) && isAgentSignUp(data)
  //   ? `${createSecurityRoute(ROUTES.agent.application.security.ROOT, 'latest', 'latest')}`
  //   : ROUTES.agent.DASHBOARD;

  return (
    <div className={classes.root}>
      <Helmet>
        {!isLoading && !isSecurityAgency(data) && GO_SQUARED_SNIPPET && <script>{GO_SQUARED_SNIPPET}</script>}
        {!isLoading && isWesternInsuranceAgent(data) && WESTERN_INSURANCE_ANALYTICS_SNIPPET && (
          <script>{WESTERN_INSURANCE_ANALYTICS_SNIPPET}</script>
        )}
        {!isLoading && isWesternInsuranceAgent(data) && WESTERN_INSURANCE_ANALYTICS_NOSCRIPT_IFRAME_URL && (
          <noscript>
            <iframe
              src={WESTERN_INSURANCE_ANALYTICS_NOSCRIPT_IFRAME_URL}
              height='0'
              width='0'
              style={{ display: 'none', visibility: 'hidden' }}
              title='google-tag-manager'
            ></iframe>
          </noscript>
        )}
      </Helmet>
      <Navbar />
      <Container maxWidth={false} className={classes.cointainer}>
        {!data || isLoading ? (
          <div className={classes.loadingContainer}>
            <LoadingContainer />
          </div>
        ) : (
          <Routes>
            <Route index element={<Navigate to={ROOT_URL} replace />} />
            <Route path={ROUTES.agent.SUMMARY} element={<AgentSummaryView agent={data} />} />
            <Route path={ROUTES.agent.CRM} element={<AgentCrmView agent={data} />} />
            <Route path={ROUTES.agent.DASHBOARD} element={<AgentDashboardView agent={data} />} />
            {/*<Route
              path={ROUTES.agent.SUPPORT}
              element={() => {
                const url =
                  'https://telivy.notion.site/Frequently-Asked-Questions-27cb43995b5741eaa3b2bad3d1a3851a?pvs=4';
                window.open(url, '_blank')?.focus();
                window.history?.back();
                return null;
              }}
            />*/}
            <Route path={ROUTES.agent.application.PARENT} element={<ApplicationDetailsView agent={data} />} />
            <Route path={ROUTES.agent.account.PARENT} element={<AccountView agent={data} />} />
            <Route path={ROUTES.agent.admin.PARENT} element={<AdminView agent={data} />} />
          </Routes>
        )}
      </Container>
    </div>
  );
};
