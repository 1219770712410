import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { AgentDTO } from 'dtos/agent';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';
import { COLORS } from 'telivy-theme';
import { SecurityCard } from 'templates/SecurityAssessments/components/SecurityCard';

import { BillingView } from './views/billing';
import { LeadMagnetView } from './views/lead-magnet';
import { ManageView } from './views/manage';
import { UsersView } from './views/users';

export interface Company {
  name: string;
  numberOfFindings: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(4),
    width: '100%',
    padding: theme.spacing(4),
    boxSizing: 'border-box',
  },
  header: {
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  button: {
    width: '100%',
    justifyContent: 'start',

    textDecoration: 'none',
    color: COLORS.BLACK,
    transition: 'all 0.3s',
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(1),
    // boxShadow: '0px 1px 0.1px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: COLORS.GREY_5,
    },
  },
  menu: {
    width: 270,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  item: {
    // fontWeight: 'bold',
    textDecoration: 'none',
    color: COLORS.BLACK,
    transition: 'all 0.3s',
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(1),
    // boxShadow: '0px 1px 0.1px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: COLORS.GREY_5,
    },
  },
  activeItem: {
    color: COLORS.BLUE_1,
    backgroundColor: COLORS.GREY_6,
  },
  content: {
    flexGrow: 1,
  },
}));

interface Props {
  agent: AgentDTO;
}

export const AccountView = ({ agent }: Props) => {
  const classes = useStyles();

  const isAgencyAdmin = agent.role === 'agency_admin' || agent.role === 'telivy_admin';

  const links = useMemo(() => {
    const links = [
      {
        label: 'Home',
        href: ROUTES.agent.account.MANAGE,
      },
      {
        label: 'Users',
        href: ROUTES.agent.account.USERS,
      },
      {
        label: 'Lead Magnet',
        href: ROUTES.agent.account.LEAD_MAGNET,
      },
    ];

    if (isAgencyAdmin) {
      links.push({
        label: 'Billing',
        href: ROUTES.agent.account.BILLING,
      });
    }
    return links;
  }, [isAgencyAdmin]);

  if (!isAgencyAdmin) {
    return (
      <SecurityCard.Badge variant='locked'>
        You do not have permission to view this page. Contact{' '}
        <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
      </SecurityCard.Badge>
    );
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Account - {agent?.agency?.name}</title>
      </Helmet>

      <div className={classes.menu}>
        <h3 className={classes.header}>Your Account</h3>
        <div>
          <div className={classes.list}>
            {links.map((el, idx) => (
              <Link
                to={el.href}
                key={idx}
                className={cx(classes.item, el.href === location.pathname && classes.activeItem)}
              >
                {el.label}
              </Link>
            ))}
            {/*<Button variant='text' color='primary' className={classes.button}>
              Log Out
            </Button>*/}
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <Routes>
          <Route index element={<Navigate replace to={ROUTES.agent.account.MANAGE} />} />
          <Route path={ROUTES.agent.account.MANAGE} element={<ManageView agent={agent} />} />
          <Route path={ROUTES.agent.account.USERS} element={<UsersView agent={agent} />} />
          <Route path={ROUTES.agent.account.LEAD_MAGNET} element={<LeadMagnetView agent={agent} />} />
          <Route path={ROUTES.agent.account.BILLING} element={<BillingView agent={agent} />} />
        </Routes>
      </div>
    </div>
  );
};
