import { makeStyles, Theme } from '@material-ui/core';
import { Badge, BadgeVariant } from 'components/Badge';
import { SecurityGrade } from 'dtos/application';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { parseScoreToGrade } from 'templates/SecurityAssessments';

const useStyles = makeStyles<Theme>((theme) => ({
  itemTitleWrapper: {
    ['@media print']: {
      width: '100%',
    },
  },
  title: {
    ...TYPOGRAPHY.LARGE,
    display: 'flex',
    justifyContent: 'start',
    marginBottom: theme.spacing(0.5),
  },
  description: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(3),
  },
  badge: {
    marginRight: theme.spacing(1),
  },
}));

export const getGradeBadgeVariant = (grade?: SecurityGrade): BadgeVariant => {
  switch (grade) {
    case SecurityGrade.A:
      return 'green';
    case SecurityGrade.B:
      return 'blue';
    case SecurityGrade.C:
      return 'yellow';
    case SecurityGrade.D:
      return 'red';
    case SecurityGrade.E:
      return 'darkRed';
    default:
      return 'grey';
  }
};

export interface Props {
  title: string;
  score?: number;
  scoreText?: string;
}

export const ExecutiveSummaryItem = ({ title, score, scoreText }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Badge
          typography={TYPOGRAPHY.LARGE}
          variant={getGradeBadgeVariant(parseScoreToGrade((score || 0) * 100))}
          className={classes.badge}
        >
          {parseScoreToGrade((score || 0) * 100)}
        </Badge>
        {title}
      </div>
      <div className={classes.description}>{scoreText}</div>
    </div>
  );
};
